<template>
    <!--人脸认证 -->
    <div class="face-auth">
        <div class="main">
            <div class="camera">
                <img src="@/assets/yq/camera-circle.png" />
            </div>
            <div class="title">录制一段自己得眨眼视频</div>
            <div class="desc">
                <div class="row">
                    <img src="@/assets/yq/auth-gz.png" />
                    <span>请在明亮光线环境下录制</span>
                </div>
                <div class="row">
                    <img src="@/assets/yq/auth-gz.png" />
                    <span>请在录制过程中眨眼3~5次</span>
                </div>
                <div class="row">
                    <img src="@/assets/yq/auth-gz.png" />
                    <span>视频时长3~5秒</span>
                </div>
            </div>
            <div class="submit" @click="getVideo">
                <zlButton text="开始录制" :background="btnBg" />
				<input
					ref="uploadVideo"
					type="file"
					@change="getVideo" 
					accept="video/*" 
					id="fileUpload"
					capture="user"
					class="upload-style"
                    :disabled="isDis"
				/>
                <!-- capture="user" -->
                <!-- https://www.cnblogs.com/ljx20180807/p/10839713.html -->
                <!-- https://www.jianshu.com/p/cdccf0e80fa8 -->
            </div>
        </div>
    </div>
</template>

<script>

import zlButton from "@/components/zlButton.vue";
import {getUpdateSignature,livePersonAuditByOcr} from "@/api/chengx/";
import { livePersonAuditByIdCard } from "@/api/user";
import axios from 'axios';

export default {
    components: {
        zlButton
    },
    data() {
        return {
            title: "身份认证",
            backVisible: false,
            ocrId:"",
			img_oss:"",
			filemaxsize : 1024*20,//20M
			params:{},
            btnBg:"#4D62B8",
            // btnBg:"#c8c8c8",
            isDis:false
        };
    },
    created() {
        this.getStorePublicInfo();
        this.params = this.$route.query;
        
    },
    methods: {
		handleChange(){
			console.log(13131);
		},
        getStorePublicInfo() {
			getUpdateSignature({
				data: {
					ossGroupId: process.env.VUE_APP_OSS_GROUP_ID,
				},
			})
			.then((res) => {
				if (res.statusCode === "00000" && res.data) {
					this.img_oss = res.data;
				}
			})
			.catch((err) => {
				console.log(err);
			});
        },
		getVideo(){
			let {files} = this.$refs.uploadVideo
			if(files.length > 0){
				console.log(files);
				if(files[0].size / 1024 > this.filemaxsize){
					this.$toast({
						message: '录制视屏不应超过10秒，请重新上传',
					});
                    return;
				}
				var formData = new FormData();
				var data = this.img_oss;
				let types = files[0].type.split("/");
				formData.append(
					"key",`${data.dir}${new Date().getTime()}.${types[1]}`
				);
				formData.append("OSSAccessKeyId", data.accessId);
				formData.append("callback", data.callback);
				formData.append("date", data.date);
				formData.append("policy", data.policy);
				formData.append("Signature", data.signature);
				formData.append("file", files[0]); // 接口需要传的参数
				//添加加载提示
                this.$store.dispatch("app/setLoading",false);
				this.$toast.loading({
					message: '认证中...',
					duration: 0,
				});
                //上传时禁止再次提交,按钮变灰色
                this.btnBg = "#c8c8c8";
                this.isDis = true;

				axios({
					url: data.host,
					headers: {
						"Content-Type": "multipart/form-data",
						"Access-Control-Allow-Origin": "*",
					},
					timeout: 15000,
					method: "post",
					// responseType: 'json',
					data: formData,
				}).then(ores=>{
					let {statusCode} = ores.data;
					let oresData = ores.data.data;
					if(statusCode === "00000"){
						console.log(oresData);
						this.params.videoResourceId = oresData.id;
						livePersonAuditByIdCard(this.params).then(res=>{
							let {status} = res;
							//全局加载loading设置true
							if(status){
								this.$store.dispatch('user/setAuthInfo').then(res=>{
									this.$toast.clear();
									this.$store.dispatch("app/setLoading",true);
									this.$router.replace({
										path: "/authRes",
									})
								})
							}else{
								this.$toast.clear();
								this.$store.dispatch("app/setLoading",true);
								this.$router.replace({
									path: "/authError",
									query:{
										authType:2,
										authTip:""
									}
								})
							}
						})
					}
				}).catch(err=>{
					this.$toast.clear();
					this.$router.replace({
						path: "/authError",
						query:{
							authType:2,
							authTip:"网络不佳，请切换网络"
						}
					})
				})
						
			}
			
		},
        
    },
};
</script>

<style scoped>
div {
    box-sizing: border-box;
}
.face-auth{
	padding-top: 50px;
	height: 100vh;
    background: #fff;
}
.face-auth .main {
    margin-top: 70px;
}
.face-auth .main div {
    text-align: center;
}
.face-auth .main .camera {
    margin: 0 auto;
}
.face-auth .main .camera img {
    width: 133.5px;
    height: 133.5px;
}
.face-auth .main .title {
    margin-top: 46.5px;
    font-size: 18px;
    font-weight: 600;
}
.face-auth .main .desc {
    margin-top: 60px;
    padding-left: 89px;
}
.face-auth .desc .row {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 12px;
}
.face-auth .desc .row img {
    height: 19px;
    width: 19px;
}
.face-auth .desc .row span {
    font-size: 15px;
    color: #999;
    margin-left: 10px;
}
.face-auth .submit {
    width: 292px;
    margin: 70px auto;
    margin-bottom: 0px;
	position: relative;
}
.ta-btn {
    background: #0471fd !important;
}
.i-btn-primary {
    background: #0471fd !important;
}
.upload-style{
	opacity: 0;
	/* display: block; */
	position: absolute;
	top: 10px;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	margin: 0;
}
</style>
